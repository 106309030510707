@font-face {
  font-family: "FontAwesome6Sharp-Light";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/fontawesome/fa-sharp-light-300.woff2') format("woff2"), url('../fonts/fontawesome/fa-sharp-light-300.ttf') format("truetype");
}

$awesome-light: "FontAwesome6Sharp-Light";


@font-face {
  font-family: "FontAwesome6Sharp-Regular";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/fontawesome/fa-regular-400.woff2') format("woff2"), url('../fonts/fontawesome/fa-regular-400.ttf') format("truetype");
}

$awesome-regular: "FontAwesome6Sharp-Regular";

@font-face {
  font-family: "FontAwesome6Sharp-Solid";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../fonts/fontawesome/fa-sharp-solid-900.woff2') format("woff2"), url('../fonts/fontawesome/fa-sharp-solid-900.ttf') format("truetype");
}

$awesome-solid: "FontAwesome6Sharp-Solid";

@font-face {
  font-family: "FontAwesome6Sharp-Brand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/fontawesome/fa-brands-400.woff2') format("woff2"), url('../fonts/fontawesome/fa-brands-400.ttf') format("truetype");
}

$awesome-brand: "FontAwesome6Sharp-Brand";