header {
  padding: $global-padding * .5 0;
  @include breakpoint(medium) {
    padding: $global-padding 0;
  }
  background-color: rgba(get-color('secondary'), .95);
  * {
    color: $white;
  }
  a {
    display: inline-flex;
    font-size: $global-font-size;
    line-height: 1.5;
    text-decoration: none;
    vertical-align: middle;
    &.lang {
      @include border-radius();
      font-size: 0.75rem;
      background-color: get-color('primary');
      font-weight: $global-weight-bold;
      padding: $global-padding * .125 $global-padding * .25;
      @include breakpoint(medium) {
        margin-left: $global-margin;
      }
      &:hover {
        text-decoration: none;
        background-color: get-color('warning');
      }
    }
    &.instagram {
      font-family: $awesome-brand;
      font-size: 1.5rem;
      line-height: 1;
      color: get-color('warning');
      &:hover {
        text-decoration: none;
        color: get-color('warning');
      }
    }
    &.address,
    &.phone {
      @include breakpoint(small down) {
        font-size: 0.875rem;
        line-height: 1.7142857142857142;
      }
    }
    &.address {

    }
    &.phone {
      font-weight: $global-weight-bold;
    }
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
  .logo {
    display: inline-flex;
    vertical-align: middle;
    line-height: $global-lineheight;
    @include breakpoint(medium) {

    }
    img {
      height: $global-lineheight * 1rem;
      @include breakpoint(large) {
        height: $global-lineheight * 2rem;
      }
    }
  }
  .row {
    @include breakpoint(medium down) {
      text-align: center;
      .xsmall-order-1,
      .xsmall-order-3 {
        flex: 1 1 calc(20% - 20px);
        vertical-align: middle;
      }
      .xsmall-order-2 {
        flex: 1 1 calc(60% - 20px);
        vertical-align: middle;
      }
      .xsmall-order-4,
      .xsmall-order-5 {
        flex: 1 1 100%;
        vertical-align: middle;
      }
    }
  }
}
footer {
  padding: $global-padding 0;
  @include breakpoint(medium) {
    padding: $global-padding * 2  0;
  }
  text-align: center;
  background-color: get-color('secondary');
  * {
    color: $white;
    font-size: 0.875rem;
    line-height: 1.7142857142857142;
  }
  .logo {
    display: inline-flex;
    margin-bottom: $global-margin;
    img {
      height: $global-lineheight * .5rem;
      @include breakpoint(medium) {
        height: $global-lineheight * 1rem;
      }
    }
  }
  a {
    &:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}
main {

}

section {
  border-bottom: 1px solid scale-color(get-color('secondary'), $lightness: 92.5%);
  padding: $global-padding 0;
  @include breakpoint(medium) {
    padding: $global-padding * 2 0;
  }
  @include breakpoint(large) {
    padding: $global-padding * 3 0;
  }
  h1,
  h2 {
    font-family: $russo;
    text-align: center;
    color: #000;
  }
  h2 {
    font-weight: $global-weight-normal;
    margin-bottom: 0;
    @include breakpoint(large) {
      margin-bottom: $global-margin;
    }
  }
  .section--header {
    text-align: center;
    margin-bottom: $global-margin;
  }
  .row-scroll {
    @include breakpoint(small down) {
      margin-left: 0;
      flex-wrap: nowrap;
      width: 100vw;
      overflow-x: auto;
      .column {
        max-width: calc(100% - 20px);
      }
    }
  }
}

.section--hero {
  display: flex;
  position: relative;
  height: $global-lineheight * 37rem;
  max-height: $global-lineheight * 37rem;
  padding: 0;
  h1,
  .subheader {
    color: $white;
    line-height: 1;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 0.125rem;
  }
  .subheader {
    font-size: 1.25rem;
    @include breakpoint(large) {
      font-size: 2.625rem;
    }
  }
  picture {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:after {
      content: " ";
      background-color: rgba($black, .5);
      position: absolute;
      inset: 0;
    }
  }
  .callout {
    padding: $global-padding 0;
    background-color: rgba(get-color('secondary'), .9);
    background-blend-mode: multiply;
    * {
      &:not(input, select) {
        color: $white;
      }
    }
    h2 {
      font-weight: $global-weight-normal;
      display: flex;
      margin-bottom: 0;
      flex-direction: column;
      margin-bottom: $global-margin;
      span {
        color: get-color('warning');
      }
    }
    fieldset {
      label {
        line-height: 1.7142857142857142;
      }
      input,
      select {
        margin-top: $global-margin * .25;
        margin-bottom: 0;
      }
      button {
        font-size: $global-font-size;
        line-height: $global-lineheight;
      }
      &:not(last-child) {
        margin-bottom: $global-margin;
      }
      &:last-child {
        margin-top: $global-margin;
      }
    }
    @include breakpoint(large) {
      padding: $global-padding 0;
    }
  }
}
.section--delivered-cars {
  background-color: $light-gray;
  .media-object {
    @include border-radius();
    overflow: hidden;
    height: calc(100% - 1.5rem);
    img {
      max-width: 100%;
    }
    .media-object-section {
      padding: 0;
      background-color: $white;
      height: 100%;
      .thumbnail {
        border: none;
        box-shadow: none;
      }
      h3 {
        font-size: $global-font-size;
        padding: $global-padding $global-padding * .5;
      }
    }
  }
}
.section--why-choose-us-cars {
  .swiper {
    padding-bottom: $global-padding * 2;
    .swiper-pagination {
      bottom: $global-lineheight * .5rem;
      .swiper-pagination-bullet {
        width: $global-lineheight * .5rem;
        height: $global-lineheight * .5rem;
      }
    }
  }
}
.section--why-choose-us {
  background-color: $light-gray;
  padding-bottom: $global-padding * 2;
  .section__why-choose-us-list {
  }
  .section__why-choose-us-item {
    display: flex;
    align-items: center;
    color: $white;
    font-weight: $global-weight-bold;
    margin-bottom: $global-margin;
    .section__why-choose-us-number {
      font-family: $russo;
      font-size: 2.625rem;
      line-height: 1.7142857142857142;
      display: block;
      width: 1.7142857142857142rem;
      flex: 0 0 25%;
      text-align: center;
    }
    .section__why-choose-us-text {
      padding-right: $global-padding;
    }
    &.section__why-choose-us-item--dark {
      background-color: get-color('secondary');
    }
    &.section__why-choose-us-item--gold {
      background-color: get-color('warning');
    }
  }
}
.section--happy-customers {
  .media-object {
    img {
      max-width: 100%;
    }
    .media-object-section {
      padding: 0;
      background-color: $white;
      .thumbnail {
        max-height: $global-lineheight * 15rem;
        border: none;
        box-shadow: none;
        overflow: hidden;
        @include breakpoint(large) {
          max-height: $global-lineheight * 11rem;
        }
      }
    }
  }
}
.section--customer-reviews {
  background-color: $light-gray;
  .macy-container {
    &:before {
      content: "";
      display: table;
      clear: both;
    }
    &:after {
      content: "";
      display: table;
      clear: both;
    }
    .demo {
      margin-bottom: 24px;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #eee;
      .demo-image {
        width: 100%;
        display: block;
        height: auto;
      }
    }
  }
}
.section--additional-services {
  .section--content {
    .column {
      @include breakpoint(large) {
        &:first-child {
          padding-right: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
  .tabs {
    z-index: 2;
    position: relative;
    border: none;
    .tabs-title {
      border-top: 1px solid $medium-gray;
      border-left: 1px solid $medium-gray;
      border-right: 1px solid $medium-gray;
      @include breakpoint(large) {
        &.is-active {
          border-right: 1px solid $white;
        }

      }
      &:last-child {
        border-bottom: 1px solid $medium-gray;
      }
      a {
        font-size: $global-font-size;
        line-height: $global-lineheight;
        font-weight: $global-weight-bold;
        border-left: 5px solid transparent;
      }
      &.is-active {
        border-left: 1px solid get-color('warning');
        a {
          color: get-color('warning');
          border-left: 5px solid get-color('warning');
        }
      }
    }
  }
  .tabs-content {
    min-height: 100%;
    z-index: 1;
    position: relative;
    border-right: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;
    border-left: 1px solid $medium-gray;
    @include breakpoint(large) {
      border-top: 1px solid $medium-gray;
      margin-left: -1px;
    }
    .tabs-panel {
      padding: $global-padding;
    }
  }
}
.section--faq-delivery {
  background-color: $light-gray;
  .accordion {
    border-radius: none;
    .accordion-item {
      .accordion-title {
        font-size: $global-font-size;
        line-height: $global-lineheight;
        font-weight: $global-weight-bold;
        padding: $global-padding;
        border-radius: 0;
        border-left: none;
        border-right: none;
        &:hover {
          color: get-color('warning');
        }
      }
      .accordion-content {
        border-left: none;
        border-right: none;
      }
      &.is-active {
        .accordion-title {
          color: get-color('warning');
        }
        .accordion-content {
          border-top: none;
          padding-top: 0;
        }
      }
    }
  }
}
.section--express-course {
  ul {
    list-style: none;
    margin-left: 0;
    li {
      position: relative;
      &:before {
        left: -1.25rem;
        position: absolute;
        color: get-color('warning');
        font-family: $awesome-regular;
        content: ""
      }
    }
  }
  figure {
    margin-bottom: $global-margin;
    text-align: center;
    img {
      width: $global-lineheight * 6rem;
    }
    figcaption {
      margin-top: $global-margin;
      b {
        font-family: $russo;
        font-weight: 400;
        display: block;
      }
    }
  }
  .callout {
    margin-top: $global-margin;
    display: flex;
    padding: $global-padding;
    overflow: hidden;
    border-radius: $global-radius * 2;
    text-align: center;
    background-color: transparent;
    position: relative;
    flex-direction: column;
    justify-content: center;
    height: $global-lineheight * 14rem;
    max-height: $global-lineheight * 14rem;
    @include breakpoint(large) {
      height: $global-lineheight * 10rem;
      max-height: $global-lineheight * 10rem;
      padding: $global-padding * 2;
    }
    picture {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &:after {
        content: " ";
        background-color: rgba($black, .75);
        position: absolute;
        inset: 0;
        background-blend-mode: multiply;
      }
    }
    * {
      color: $white;
    }
    h3 {
      font-size: 1.75rem;
      font-weight: 400;
      font-family: $russo;
      line-height: 1;
      margin: 0;
      @include breakpoint(large) {
        line-height: 1.5;
      }
    }
    p {
      font-weight: $global-weight-bold;
      margin-bottom: $global-margin;
    }
    .input-group {
      display: inline-flex;
      align-self: center;
      width: 80vw;
      margin: 0;
      @include breakpoint(large) {
        width: 50vw;
      }
      .input-group-field {
        color: $black;
      }
    }
  }
}
.section--partners-logos {
  margin-bottom: $global-margin;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: map-safe-get($grid-margin-gutters, xsmall) * .5;
  @include breakpoint(medium) {
    gap: map-safe-get($grid-margin-gutters, xsmall);
  }
  .section__partners-logo {
    height: $global-lineheight * 1rem;
    @include breakpoint(medium) {
      height: $global-lineheight * 1.5rem;
    }
  }
}
.section--stats {
  background-color: get-color('secondary');
  margin-bottom: $global-margin;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  * {
    color: $white;
  }
  .section--stats-item {
    text-align: center;
    padding: $global-padding;
    &:nth-child(1) {
      flex: 1 1 100%;
      padding-bottom: 0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      flex: 1 1 25%;
    }
    @include breakpoint(large) {
      text-align: left;
      padding: $global-padding $global-padding * 1.5;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        flex: 1 1 33%;
      }
      &:nth-child(1) {
        padding-bottom: $global-padding;
      }
    }
    .stats-highlight,
    .stats-year,
    .stats-number {
      font-family: $russo;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1;
    }
    .stats-highlight {

    }
    .stats-year {
      color: get-color('warning');
    }
    .stats-number {
      color: get-color('warning');
    }
    .stats-text {
      font-weight: 600;
    }
  }
}