@font-face {
	font-family: 'Russo';
	src: url('../fonts/russo/RussoOne.eot');
	src: url('../fonts/russo/RussoOne.eot?#iefix') format('embedded-opentype'),
		url('../fonts/russo/RussoOne.woff2') format('woff2'),
		url('../fonts/russo/RussoOne.woff') format('woff'),
		url('../fonts/russo/RussoOne.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

$russo: "Russo";